<template>
	<div class="pageContainer ModuleRoles">
		<!--   <el-row type="flex" style="margin-bottom: 20px" justify="start">
      <el-button
        size="small"
        icon="el-icon-plus"
        @click="formAdd"
        type="primary"
        >新增</el-button
      >
    </el-row> -->
		<el-row type="flex" style="margin-bottom: 20px" justify="start">
			<el-tabs v-model="activeName" type="border-card" style="width: 100%">
				<el-tab-pane v-for="item in tabMapOptions" :key="item.key" :label="item.label" :name="item.key">
					<el-table :data="tableData" style="width: 100%; margin-bottom: 20px" row-key="id" border stripe
						default-expand-all :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
						<el-table-column type="index" label="序号" width="60px"></el-table-column>
						<el-table-column prop="role_name" label="角色名称"></el-table-column>
						<el-table-column prop="role_code" label="角色代码"></el-table-column>
						<el-table-column prop="sort" width="80px" label="排序"></el-table-column>
						<el-table-column fixed="right" label="操作" width="200">
							<template slot-scope="scope">
								<!--  <el-popconfirm
                  width="250"
                  confirmButtonText="确定"
                  cancelButtonText="取消"
                  icon="el-icon-info"
                  iconColor="red"
                  @confirm="handleDel(scope.row)"
                  placement="left"
                  title="是否确定删除？"
                >
                  <a
                    slot="reference"
                    size="small"
                    style="color: red; font-size: 12px; margin-right: 10px"
                    >删除</a
                  >
                </el-popconfirm>
                <el-button
                  @click="handleModify(scope.row)"
                  type="text"
                  size="small"
                  >修改</el-button
                > -->
								<el-button @click="showAuth(scope.row)" type="text" size="small">模块权限配置</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-tab-pane>
			</el-tabs>
		</el-row>
		<el-dialog :title="modalTitle" :visible.sync="isShowModal" :close-on-click-modal="false"
			:destroy-on-close="true">
			<el-form :model="formData" ref="ruleForm" label-width="120px" :rules="rules"
				style="max-height: 450px; overflow-y: auto; min-height: 300px">
				<el-form-item label="角色" v-if="false">
					<el-cascader ref="firstCascader" :options="treeData" :props="firstCascaderProps"
						v-model="formData.dept_id" :show-all-levels="false" @change="handleCascaderChange"
						placeholder="请选择" style="width: 50%" filterable clearable></el-cascader>
				</el-form-item>
				<el-form-item label="角色名称" prop="role_name">
					<el-input v-model.trim="formData.role_name" style="width: 50%" />
				</el-form-item>
				<el-form-item label="角色代码" prop="role_code">
					<el-input v-model.trim="formData.role_code" style="width: 50%" />
				</el-form-item>
				<el-form-item label="排序号">
					<el-input v-model.trim="formData.sort" style="width: 50%" />
				</el-form-item>
			</el-form>
			<el-row>
				<el-col :span="22" style="text-align: right">
					<el-button size="small" type="default" @click="isShowModal = false">取消</el-button>
					<el-button size="small" type="primary" @click="submitForm('ruleForm')">保存</el-button>
				</el-col>
			</el-row>
		</el-dialog>

		<el-dialog title="模块菜单" :visible.sync="dialogAuth" :close-on-click-modal="false" :destroy-on-close="true"
			width="400px" top="60px">
			<el-tree ref="authTree" :props="{ children: 'children', label: 'name' }" node-key="id" :data="authData"
				:default-expand-all="true" show-checkbox>
			</el-tree>

			<div style="
          text-align: right;
          padding: 10px;
          margin-top: 10px;
          border-top: 1px solid #ccc;
        ">
				<el-button type="primary" icon="el-icon-s-claim" size="small" @click="saveRoleMenu">保存</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import api from "@/api/api";
	export default {
		name: "deptRole",
		data() {
			return {
				visible: false,
				isShowModal: false,
				dialogAuth: false,
				modalTitle: "添加",
				formData: {},
				firstCascaderProps: {
					label: "dept_name",
					value: "id",
					children: "children",
					emitPath: false,
					checkStrictly: true,
				},
				//table
				tabMapOptions: [{
					label: "模块角色",
					key: "CN",
				}, ],
				activeName: "CN",
				tableData: [],
				rules: {
					role_name: [{
						required: true,
						message: "请输入角色名称",
						trigger: "blur",
					}, ],
					role_code: [{
						required: true,
						message: "请输入角色代码",
						trigger: "blur",
					}, ],
				},
				isShowMenu: false,
				menuTitle: "",
				menuIds: "",
				roleId: null,

				treeData: [],
				treeProps: {
					children: "children",
					label: "dept_name",
				},
				authData: [],
				activeItem: null,
			};
		},
		watch: {
			"formData.dept_id"() {
				if (this.$refs.firstCascader) {
					this.$refs.firstCascader.dropDownVisible = false; //监听值发生变化就关闭它
				}
			},
		},
		mounted() {
			this.getList();
			this.getTree();
			this.getModuleList();
		},
		methods: {
			getTree() {
				let _this = this;
				this.$http.post(api.tea_deptList).then((res) => {
					if (res.data && res.data.length > 0) {
						_this.deleteEmptyGroup(res.data).then((result) => {
							_this.treeData = result;
						});
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			// 处理没有children的分组
			deleteEmptyGroup(treeData) {
				return new Promise((resolve) => {
					function traversal(data) {
						data.map((item) => {
							for (let info in item) {
								if (item["children"]) {
									if (item["children"].length > 0) {
										traversal(item["children"]);
									}
									if (item["children"].length == 0) {
										delete item["children"];
									}
								}
							}
						});
					}

					traversal(treeData);
					resolve(treeData);
				});
			},
			getList() {
				this.$http.post("/api/zichan_sys_role_list", {
					pagesize: 100
				}).then((res) => {
					this.tableData = res.data;
				});
			},
			formAdd() {
				this.isShowModal = true;
				this.formData = {
					sort: 1,
				};
				this.modalTitle = "添加";
			},
			handleModify(row) {
				this.isShowModal = true;
				this.modalTitle = "修改";
				this.formData = row;
			},
			handleDel(row) {
				this.$http
					.post(api.roleDel, {
						id: row.id,
					})
					.then((res) => {
						this.$message.success("删除成功");
						this.getList();
					});
			},
			handleCascaderChange() {},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$http.post("/api/sys_role_edit", this.formData).then((res) => {
							this.$message.success("保存成功");
							this.getList();
							this.isShowModal = false;
							return;
						});
					}
				});
			},
			getModuleList() {
				let _this = this;
				this.$http.post("/api/sys_module_list").then((res) => {
					console.log(res.data);
					for (let tree of res.data) {
						delete tree.id;
						tree.name = tree.group_name;
						for (let child of tree.children) {
							child.name = child.m_name;
						}
					}

					_this.authData = res.data;
				});
			},
			showAuth(item) {
				this.activeItem = item;
				this.dialogAuth = true;
				this.getModule();
			},
			getModule() {
				this.$http
					.post("/api/sys_module_role_list", {
						ktype: "role_id",
						keyword: this.activeItem.id,
					})
					.then((res) => {
						let kys = [];
						for (let a of res.data) {
							kys.push(a.m_id);
						}
						this.$nextTick(() => {
							this.$refs["authTree"].setCheckedKeys(kys);
						});
					});
			},
			saveRoleMenu() {
				let menus = this.$refs["authTree"]
					.getCheckedNodes(false, true)
					.map((e) => {
						return e.id;
					})
					.join(",");

				this.$http
					.post("/api/sys_module_role_edit", {
						role_id: this.activeItem.id,
						m_ids: menus,
					})
					.then((res) => {
						this.$message.success("保存成功");
						this.dialogAuth = false;
					});
			},
		},
	};
</script>

<style scoped lang="less" type="text/less">
	.DeptRole {
  .treeContainer {
    height: 100%;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
    border: 1px solid rgba(220, 223, 230, 0.6);

    .treeTitle {
      height: 40px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #409eff;
      padding-left: 6px;
    }
  }
}
</style>
